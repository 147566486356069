@font-face {
  font-family: "museo-sans-regular";
  font-style: normal;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  src: url("https://use.typekit.net/af/491586/00000000000000003b9b1e2d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff");
}
@font-face {
  font-family: "museo-sans-bold";
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  src: url("https://use.typekit.net/af/9baf4a/00000000000000003b9b1e2f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff");
}

:root {
  --background-color: #f7f7f7;
  --museo-sans-regular: "museo-sans-regular", Helvetica, Arial, sans-serif;
  --museo-sans-bold: "museo-sans-bold", Helvetica, Arial, sans-serif;
  --mymaths-blue:#172e50;
  --mymaths-blue-darker:rgb(16, 32, 56);
  --grey-light:rgb(224, 224, 224);
  --rich-black:rgb(0, 0, 0);
  --green: rgb(77, 172, 143);
  --box-shadow: rgb(0 0 0 / 16%);
  --white: #FFFFFF;
}
html[data-theme='light'] {
  --background-color: #ccc;
}

html[data-theme='dark'] {
  --background-color: #212a2e;
}

body {
  font-family: var(--museo-sans-regular);
    font-size: 20px;
    background-color: var(--background-color);
    margin:8px;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--mymaths-blue);
}

h1 {
  font-family: var(--museo-sans-bold);
  color: var(--mymaths-blue);
  box-sizing: border-box;
  padding: 0;
  letter-spacing: -1px;
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 27px;
}

h2 {
  color: var(--mymaths-blue);
  box-sizing: border-box;
  padding: 0;
  letter-spacing: -.5px;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.3;
  margin: 0 0 27px;
}

p {
  box-sizing: border-box;
  padding: 0;
  margin: 0 0 27px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color .3s ease;
  font-family: var(--museo-sans-regular);
  font-weight: 300;
  line-height: 1.6;
  color: var(--rich-black);
  font-size: 18px;
  max-width: 700px;
}

a{
  color: var(--rich-black);
}

table {
  border-collapse: collapse;
  empty-cells: hide;
}



td,th {
  box-sizing: border-box;
  padding: 0;
  margin: 0 0 27px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: color .3s ease;
  font-family: var(--museo-sans-regular);
  font-weight: 300;
  line-height: 1.6;
  color: var(--rich-black);
  font-size: 16px;
  max-width: 700px;
  padding: 16px;
  border: 1px solid var(--grey-light);
}
th {
  font-family: var(--museo-sans-regular);
  color: var(--mymaths-blue);
    font-weight: 700;
    font-size: 20px;
    text-align: left;
}

thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  top: 0;
  background-color: var(--green);
}

thead td {
  color: var(--mymaths-blue);
  font-weight: 700;
  font-size: 20px;
}
.table {
  margin-bottom: 30px;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--white);
  padding: 40px;
  box-shadow: 0px 0px 3px 1px var(--box-shadow);
  border-radius: 15px;
  }
  .logo {
    max-width: 400px;
    width: 100%;
    display: block;
    margin-bottom: 40px
  }

.logo img{
  height: 100%;
}

.intro p{
  margin-bottom: 27px;
    font-family: var(--museo-sans-regular);
    font-weight: 300;
    font-size: 25px;
    line-height: 1.3;
}

.message {
  background-color: var(--background-color);
  max-width: 650px;
  border-radius: 15px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50px 1fr;
  grid-template-columns: 50px 1fr;
  grid-gap: 16px;
  margin-bottom: 27px;
  padding: 16px;
}

.message .icon {
  border-right: 1px solid rgba(0, 0, 0, 0.13);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 16px;
}

.message .text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
}

.message p {
  font-size:16px;
  margin-bottom: 0;
  color: var(--mymaths-blue);
}


.button-controls {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr max-content max-content max-content;
  grid-template-columns: 1fr max-content max-content max-content;
  grid-gap: 16px;
}

.input {
  margin-bottom: 27px;
  font-weight: 300;
  font-family: var(--museo-sans-regular);
  font-size: 18px;
  line-height: 1;
  width: auto;
}



.input input {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  padding: 11px;
  transition: all 0.2s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 18px;
  font-family: var(--museo-sans-regular);
  background: --ba;
  border-radius: 0;
  color: var(--rich-black);
  width: 100%;
  margin: 0;
  border: 3px solid var(--mymaths-blue);
  box-sizing: border-box
}
.input .search {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB2aWV3Qm94PSIwIDAgMTQwIDE0MCIgd2lkdGg9IjE0MCIgaGVpZ2h0PSIxNDAiPjxnIHRyYW5zZm9ybT0ibWF0cml4KDUuODMzMzMzMzMzMzMzMzMzLDAsMCw1LjgzMzMzMzMzMzMzMzMzMywwLDApIj48cGF0aCBkPSJNMC43NTAgOS44MTIgQTkuMDYzIDkuMDYzIDAgMSAwIDE4Ljg3NiA5LjgxMiBBOS4wNjMgOS4wNjMgMCAxIDAgMC43NTAgOS44MTIgWiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMTcyRTUwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMy4wNTYgNC42Mikgcm90YXRlKC0yMy4wMjUpIj48L3BhdGg+PHBhdGggZD0iTTE2LjIyMSAxNi4yMkwyMy4yNSAyMy4yNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMTcyRTUwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41Ij48L3BhdGg+PC9nPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-size: 27px 27px;
  background-position: 10px center;
  padding-left: 40px;
}

.warning-icon {
  height: 20vmin;
  pointer-events: none;
  margin-bottom: 20px
}

.not-found {
  text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column
}

.loading {
  display: flex;
  justify-content: center;
}

@media print {
  /* @page {
  margin: 2cm;
} */

/* target the first page only */
/* @page :first {
  margin-top: 6cm;
} */

/* target left (even-numbered) pages only */
/* @page :left {
  margin-right: 4cm;
} */

/* target right (odd-numbered) pages only */
/* @page :right {
  margin-left: 4cm;
} */

  body {
    margin: 0;
    color: var(--rich-black);
    background-color: var(--white);
  }
  /* h1 {
  break-before: always;
} */

  p {
    max-width: auto;
  }

  /* a::after {
    content: " (" attr(href) ")";
  } */

  a{
    text-decoration: none;
  }

  .container::after {
    content: "Copyright site.com";
    display: block;
    text-align: center;
  }

  .wrapper {
    max-width: auto;
    margin: 0 auto;
    background-color: var(--white);
    padding: 10px;
    box-shadow: none;
    border-radius: 0;
    }
    table, img, svg {
      break-inside: avoid;
    }
    .button-controls{
      display: none;
    }
   
    .pagebreak { page-break-before: always; }

}
@media print {
  table tr {
    orphans: 3;
    break-inside: avoid-page; }
  td {
    padding: 10px;
    border: 1px solid var(--grey-light) }
  thead {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    background-color: #e0e0e0; } 
  }

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--mymaths-blue);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  .button {
    box-sizing: border-box;
    padding: 0;
    margin: 0 10px 27px 0;
    will-change: transform;
    transition: background-color .2s ease;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 300;
    font-size: 18px;
    font-family: var(--museo-sans-regular);
    line-height: 1.6;
    text-decoration: none;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 0;
    box-shadow: inset 0 0 0 2px var(--mymaths-blue);
    border-radius: 50px;
    background: transparent;
    color: #172e50;
    cursor: pointer;
}
.button.hover, .button:hover {
  color: var(--white);
  background: var(--mymaths-blue);
}
  .button.medium {
    padding: 11px 27px;
    font-size: 18px;
}
